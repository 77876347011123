.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  
  .button-container .ant-btn {
    margin: 5px;
  }
  
  @media (max-width: 600px) {
    .button-container {
      flex-direction: column;
      align-items: stretch;
    }
  
    .button-container .ant-btn {
      margin: 10px 0;
    }
  
    .button-container .ant-divider-vertical {
      display: none;
    }
  }
  